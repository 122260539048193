// You can customize the template with the help of this file

//Template config options
const baseDevAPIURL = process.env.REACT_APP_BASE_DEV_API || 'https://membership-api.sanasoftware.net/api'
//const baseProdAPIURL = process.env.REACT_APP_BASE_PROD_API || 'http://185.227.111.191:8080/api'
//let baseApiUrl = process.env.NODE_ENV === 'production' ? baseProdAPIURL : baseDevAPIURL;
const themeConfig = {
  app: {
    appName: 'Alburouj Sports Club',
    appLogoImage: require('@src/assets/images/logo/broujLogo.png').default,
     //api_url:'http://185.227.111.191:8080/api' // Prod
    api_url:baseApiUrl
  },
  layout: {
    isRTL: false,
    skin: 'light', // light, dark, bordered, semi-dark
    routerTransition: 'fadeIn', // fadeIn, fadeInLeft, zoomIn, none or check this for more transition https://animate.style/
    type: 'horizontal', // vertical, horizontal
    contentWidth: 'full', // full, boxed
    menu: {
      isHidden: false,
      isCollapsed: false
    },
    navbar: {
      // ? For horizontal menu, navbar type will work for navMenu type
      type: 'floating', // static , sticky , floating, hidden
      backgroundColor: 'white' // BS color options [primary, success, etc]
    },
    footer: {
      type: 'static' // static, sticky, hidden
    },
    customizer: false,
    scrollTop: true // Enable scroll to top button
  }
}

export default themeConfig
