// ** Logo
import logo from '@src/assets/images/logo/logo.png'
import SpinnerFlex from '@src/views/components/spinners/SpinnerFlex'

const SpinnerComponent = () => {
  return (
    <div className='fallback-spinner vh-100' id="loading">
      <img className='fallback-logo' src={logo} alt='logo' />
      <SpinnerFlex/>
    </div>
  )
}

export default SpinnerComponent
