import { Fragment } from 'react'
import { Spinner } from 'reactstrap'

const SpinnerFlex = () => {
  return (
    <Fragment>
    
      <div className='d-flex justify-content-center align-items-center'>
        <Spinner />
        <strong>Loading...</strong>
      </div>
    </Fragment>
  )
}
export default SpinnerFlex
